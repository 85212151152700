import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <a href="">Page 1</a>
      <a href="">Page 2</a>
      <a href="">Page 3</a>
    </div>
  );
}

export default App;
